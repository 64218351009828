<template>
  <div class="w3-padding-32">
    <v-main>
      <v-snackbar  top v-model="snackbar" :timeout="3000" :color="color" class="text-center mt-3">
        <span class="white--text">{{message}}</span>
        <template v-slot:action="{attrs}">
          <v-btn  v-bind="attrs" @click="snackbar = !snackbar"  text class="white--text">
            <v-icon class="text-white">mdi-close</v-icon>
          </v-btn>
        </template>

      </v-snackbar>

      <v-img>
        <div v-if="getIntializeTransfer===''" style="margin-top: 40vh!important;">
           <div class="text-center w3-center justify-center" style="margin: 0 auto!important; ">

          <v-progress-circular size="40" indeterminate color="#AB005E"></v-progress-circular>

        </div>
        </div>
        <div v-else>
          <v-row class="px-2 mt-2">
            <v-col cols="12" md="4" lg="4" sm="3">
              &nbsp;
            </v-col>
            <v-col cols="12" md="4" lg="4" sm="6">
              <v-card class=" " elevation="4" >
                <v-card-title class="rounded-0" style="   box-shadow: 0px 6px 25px rgb(0 0 0 / 7%)!important;">
                  <div class="d-flex flex-row">
                    <v-avatar size="42" class="elevation-1">
                      <img :src="getIntializeTransfer.bizlogo">

                    </v-avatar>
                    <div class="ml-3 mt-2">
                      <span class="sectionHeaders ">{{getIntializeTransfer.busname}}</span>

                    </div>
                  </div>
                  <v-spacer></v-spacer>



                </v-card-title>
                <v-card-text class="py-4">
                  <p class="text-center topic1" style="font-size:14px!important">
                    Kindly Transfer
                    <b>{{getIntializeTransfer.currency}} {{getIntializeTransfer.amount}}</b>
                    to the account below to pay to {{getIntializeTransfer.busname}}
                  </p>
                  <div
                      class=" text-center w3-center"
                      style="width:100%!important;     padding: 25px 0!important;  ;    background: #f5f5f5!important;"
                  >
                    <div v-if="getTransferTime!==0">
                      <p class="topic1" style=" color: #011b33;!important; font-weight: 500!important;">
                        <span style="font-size:17px!important;">{{getIntializeTransfer.bank_name}}</span>
                      </p>
                      <p class="topic1 mb-0" style="color:#011b33!important; font-weight: 500!important;">

                        <span class="sectionHeaders" style="font-size:18px!important; color:#011b33!important">{{getIntializeTransfer.accountno}}</span>
                        <v-btn class="mt-n1" small icon color="#011b33" @click="copy(getIntializeTransfer.accountno)">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"></path></svg>
                        </v-btn>
                      </p>
                    </div>
                    <div v-else>
                      <p class="topic1" style=" color: #011b33;!important; font-weight: 500!important;">
                        <span style="font-size:17px!important;">Account number has expired</span>
                      </p>
                      <p>
                        <v-btn plain @click="initializeTransfer" :loading="initializeLoader">
                          <v-icon color="#011b33" left>mdi-restart</v-icon>
                          <span class="sectionHeaders" style="font-size:13px!important; color:#011b33!important">Try again</span>

                        </v-btn>
                      </p>
                    </div>



                  </div>
                  <div class="text-center mt-2" v-if="getTransferTime!==0">
                    <span style="color:#9c9c9c; " class="text-caption">Use this account for this transaction only</span>
                    <br>
                    <p class="topic1"  style=" color:#ab005e!important;">Account expires in <b>{{timeCalculator[1]}}mins : {{timeCalculator[2]}} secs</b></p>
                  </div>



                  <v-btn
                      v-if="getTransferTime!==0"
                      :loading="loader"
                      class="payButton"
                      block
                      elevation="2"
                      @click="verifyTransfer"


                  >
                    <span class=" text-capitalize">I've sent the money</span>
                    <v-icon right>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>

                  <v-btn
                    :to="{
                        name:'checkOut',
                        params:{access_token:$route.params.token, reference: $route.params.reference}
                      }" class="" block text color="#011b33">
                    <v-icon left>mdi-refresh</v-icon>
                    <span class="text-capitalize">Change payment method</span>
                  </v-btn>


                </v-card-text>
              </v-card>
              <p class="text-center mt-3">
                <span class="topic1">Powered by </span> <v-avatar size="27"><img src="@/assets/img/logo.png" > </v-avatar>
              </p>
            </v-col>
          </v-row>
        </div>

      </v-img>

    </v-main>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "checkOut",
  data(){
    return {

      rules: {
        required: value => !!value || 'Required',
        length: value => value.length >= 3 || 'Enter a valid details',
        emailFormat: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        passwordFormat: value => {
          const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
          return pattern.test(value) || "Invalid Password Format"
        }




      },

      loader:false,
      snackbar:false,
      message:'',
      color:'',
      option:'1app',
      oneApp:false,
      email:'',
      password:'',
      visible:false,
      loginLoader:false,
      loginStatus:false,
      otp:'',
      transferMode:false,
      initializeLoader:false

    }
  },
  computed:{
    ...mapGetters([
      'getIntializeTransfer',
      'getTransferTime'

    ]),
    timeCalculator(){
      //returns the user time in a formatted form
      if(this.timer===0)return ['00','00','00']
      let hour = Math.floor(this.getTransferTime/3600);
      let min = Math.floor((this.getTransferTime%3600)/60);
      let sec = this.getTransferTime% 3600 % 60;
      return [
        this.setTime(hour),
        this.setTime(min),
        this.setTime(sec)
      ]
    },

  },
  methods:{
    copyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = 0;
      // Clean up any borders.
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      // Avoid flash of the white box if rendered for any reason.
      textArea.style.background = 'transparent';
      textArea.value = text;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      navigator.clipboard.writeText(text)
          .then(() => {
            alert(`Account number copied!`)
          })
          .catch((error) => {
            alert(`Copy failed! ${error}`)
          })

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        //console.log('Copying text command was ' + msg);
      } catch (err) {
      }
      document.body.removeChild(textArea);
    },
    copy(text) {
      this.copyTextToClipboard(text);
    },
    //formats timer countdown for users
    setTime(value){
      if(value <= 9){
        let newTime = String(value);
        return newTime.padStart(2,0)
      }
      return (value);

    },

    async verifyTransfer(){
      this.loader=true
      await this.$store.dispatch('checkDynamicTransfer',{
        token: this.$route.params.token,
        reference: this.$route.params.reference,
        amount : this.getIntializeTransfer.amount,
        accntno: this.getIntializeTransfer.accountno
      })
          .then(response=>response.json())
          .then(async response=>{
            if(response.status && response.authorized === '01'){
              await this.$store.commit('updateVerifiedTransaction',{
                status:true,
                message:'Transaction completed',
                token:this.$route.params.access_token,
                reference:this.$route.params.reference,

              })
              await this.$router.push({name:'confirmTransaction'})
              return
            }
            this.loader=false
            this.snackbar=true
            this.message = response.message
            this.color='error'
          }).catch(e=>console.log(e.message))
    },
    async initializeTransfer(){
      this.initializeLoader=true
      await this.$store.dispatch('intializeTransfer', {
        token: this.$route.params.token,
        reference: this.$route.params.reference,
        gateway: this.$route.params.gateway,
      })
      this.initializeLoader=false
    }
  },

  async mounted() {
    await this.$store.dispatch('intializeTransfer', {
      token: this.$route.params.token,
      reference: this.$route.params.reference,
      gateway: this.$route.params.gateway,
    })
    if(!(!!this.$route.params.token && !!this.$route.params.reference))return await this.$router.push({name:'home'})

    // if(this.getIntializeTransfer.transtoken === ''){
    //   this.$store.commit('updateTransferTimer', this.getIntializeTransfer.countdown)
    // }

    await this.$store.dispatch('transferTimeCountDown')

  },
  async beforeDestroy(){
    await this.$store.commit('updateTransferTimer', '')
  }
}
</script>

<style scoped>
html,body{
  background: #E5E5E5!important;
}
.cards{
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%)!important;
  border: 0 solid rgba(0,0,0,.125)!important;
  border-radius: 1rem!important;
}
.sectionHeaders{
  font-size: 1.1rem;
  line-height: 1.625;
  color: #344767;
  font-weight: 600;
  font-family: Poppins, sans-serif!important;

}
.NqanGvcWiCU6BArN5gOD {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
  width: 105px;
  height: 105px;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.1s ease-in;
  pointer-events: none;
  will-change: transform;
}

element.style {
}
.PXlarHSsuCPnwMqPtjzN {
  position: relative;
  top: 21px;
  left: -11px;
  z-index: 1;
  display: block;
  width: 160px;
  padding: 9px 0 6px 0;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  background-color: #ab005e;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  transform: rotate( 45deg);
  transition: all 0.1s ease-in;
  pointer-events: auto;
}
.HfwXc2kW0vSgTdBa0sD6 {
  margin-left: -7px;
  color: #F2F2F2;
  font-size: 8px;
  text-transform: uppercase;
}
.sv3atfFjLzu9yiAmaUZk {
  margin-left: -7px;
  color: #ffffff;
  font-size: 14px;
}
.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::after {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #505960;
  transition: all 0.1s ease-in;
  content: '';
}
.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::after {
  right: 0;
  bottom: 0;
}
.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::before, .NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::after {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #505960;
  transition: all 0.1s ease-in;
  content: '';
}
.NqanGvcWiCU6BArN5gOD.pel451_UGSUBPu_cv4YV::before {
  top: 0;
  left: 0;
}
.topic1{
  line-height: 1.625!important;
  font-weight: 400!important;
  font-size: 12px!important;
  margin-top: 0!important;
  margin-bottom: 1rem!important;
  color: #444!important;
  font-family: Poppins, sans-serif!important;
}
label{
  line-height: 1.625;
  font-weight: 400;
  font-size: 13px;
  color: #67748e;
  text-align: center!important;
  font-family: Poppins, sans-serif!important;
}
.payButton{
  background: #AB005E!important;
  border-color: #AB005E!important;
  color: #fff!important;
  width: 150px!important;
  padding: 0.6rem 1rem!important;
  font-size: 13px!important;
  opacity: 1!important;
  border-radius: 3px!important;
  font-weight: 400!important;
}
label{
  line-height: 1.625;
  font-weight: 400;
  font-size: 13px;
  color: #67748e;
  text-align: center!important;
  font-family: Poppins, sans-serif!important;
}
.cancel{
  background: #fff!important;
  color: #AB005E!important;
  padding: 0.6rem 1rem!important;
  font-size: 13px!important;
  opacity: 1!important;
  border-radius: 3px!important;
  font-weight: 400!important;
  line-height: 1.5!important;
  border: 1px solid #AB005E!important;
}
</style>
